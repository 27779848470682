import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import settingsParams from '../settingsParams';
import { TierCards } from './tier-cards';
import { classes } from './tiers-program.st.css';
import { DataHook } from '../../../types/data-hook';

interface TiersProgramProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
}

export const TiersProgram: React.FC<TiersProgramProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const showTiersPageTitle = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showTiersPageTitle);
  const showTiersPageSubtitle = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showTiersPageSubtitle);

  return (
    <div className={classes.root} data-hook={DataHook.TiersProgram}>
      <div className={classes.headerSection}>
        {showTiersPageTitle && (
          <Text tagName="h2" className={classes.sectionTitle}>
            {settings.get(settingsParams.tiersTitle)}
          </Text>
        )}
        {showTiersPageSubtitle && (
          <Text className={classes.sectionSubtitle}>{settings.get(settingsParams.tiersSubtitle)}</Text>
        )}
      </div>
      <TierCards
        {...{
          simpleTiers,
          simpleRules,
          simpleRewards,
          tiersProgramSettings,
        }}
      />
    </div>
  );
};
