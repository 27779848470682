import React from 'react';
import { useStyles, useSettings } from '@wix/tpa-settings/react';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';

import { SimpleReward, SimpleRule } from '../../../types/domain';
import { Header } from './header';
import { TabState } from '../../../types/settings';
import { DetailsCard } from './details-card';
import { classes } from './main-program.st.css';
import settingsParams from '../settingsParams';
import { DataHook } from '../../../types/data-hook';

interface MainProgramProps {
  tabState: TabState;
  loyaltyProgram: LoyaltyProgram;
  isLoggedIn: boolean;
  hasMyRewardsPage: boolean;
  onNavigateToMyRewardsPage(): void;
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  showTiers: boolean;
}

export const MainProgram: React.FC<MainProgramProps> = ({
  tabState,
  loyaltyProgram,
  isLoggedIn,
  hasMyRewardsPage,
  onNavigateToMyRewardsPage,
  simpleRules,
  simpleRewards,
  showTiers,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const iconPath = loyaltyProgram?.pointDefinition?.icon?.url;
  const showHeader = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showHeader);
  const showProgramContent = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.booleans.showProgramContent);

  return (
    <div className={classes.root} data-hook={DataHook.LoyaltyPageWrapper}>
      {showHeader && (
        <Header
          loyaltyProgram={loyaltyProgram}
          isLoggedIn={tabState ? tabState === TabState.AfterSignUp : isLoggedIn}
          hasMyRewardsPage={hasMyRewardsPage}
          onNavigateToMyRewardsPage={onNavigateToMyRewardsPage}
        />
      )}
      {showProgramContent && (
        <ol className={classes.detailsContainer}>
          <DetailsCard
            dataHook={DataHook.DetailsCardSignUp}
            step={1}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.signUpTitle)}
            details={[{ description: settings.get(settingsParams.signUpDescription) }]}
            showTiers={showTiers}
          />
          <DetailsCard
            dataHook={DataHook.DetailsCardEarnPoints}
            step={2}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.earnPointsTitle)}
            details={
              showTiers
                ? [{ description: settings.get(settingsParams.earnPointsDescription) }]
                : simpleRules.filter((rule) => !rule.tierId)
            }
            showTiers={showTiers}
          />
          <DetailsCard
            dataHook={DataHook.DetailsCardRedeemPoints}
            step={3}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.redeemPointsTitle)}
            details={
              showTiers
                ? [{ description: settings.get(settingsParams.redeemPointsDescription) }]
                : simpleRewards.filter((reward) => !reward.tierId)
            }
            showTiers={showTiers}
          />
        </ol>
      )}
    </div>
  );
};
